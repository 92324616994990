<template>
  <div id="RSPresciption" class="flex h-screen  items-center justify-center inset-0 ...">
    <table
      style="width:70%; background: white;margin: auto!important"
      class="text-center shadow-md m-3"
    >
      <tr class="HeaderTableColor">
        <th class="text-center">
          {{ $t("Prescriptions") }}
        </th>
        <th class="text-center">
          {{ $t("Date") }}
        </th>
        <th class="h-20 text-center">{{ $t("Doctors") }}</th>
        <th class="text-center ">
          <div class="MyPopClose">
            <feather-icon
              class="m-6"
              icon="XIcon"
              svgClasses="w-10 h-10 hover:text-primary stroke-current"
              @click.stop="$emit('closePop')"
            />
          </div>
        </th>
      </tr>
      <tr
        class="text-xl h-20"
        v-for="(item, index) in Model.slice(0, 2)"
        :key="index"
        v-if="Model.length > 0"
      >
        <td>
          {{ item.Name }}
        </td>
        <td>
          {{ item.DateFormatted  }}
        </td>
        <td>
          <div>
            <imageLazy
                      :imageStyle="{ 'border-radius': '40px','width':'80px','height':'80px',size:'100px' }"
                      :imageClass="'doctorImage'"
                      :src="baseURL + item.DoctorImage"
                      placeHolderType="doctor"
                    />
          </div>

          <p v-if="item.DoctorName">{{ $t("Dr") }}.{{ item.DoctorName }}</p>
        </td>
        <td
          class="bg-primary text-white "
          @click="Download(item, index)"
        >
          <div>
            <feather-icon
              icon="DownloadIcon"
              @click="Download(item, index)"
              svgClasses="h-4 w-4"
            />
          </div>
          <p> {{ $t("Download") }} </p>
        </td>
      </tr>
      
      <tr class="text-xl h-20" v-if="Model.length > 0">
        <td></td>
        <td colspan="0">
          <vs-button
            color="primary"
            @click="$router.push({ name: 'PrescriptionsList' })"
          >
            {{ $t("SeeAllPrescriptions") }}
          </vs-button>
        </td>
        <td></td>
      </tr>
        <tr v-else>
         <td></td>
         <td><h3  class="w-full sm:w-auto text-center text-primary mt-5">{{$t("NoDataToshow")}}</h3></td>
         <td></td>            
          </tr>
    </table>
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  components:{
    imageLazy
  },
  data() {
    return {
      baseURL: domain,
      Model: [],
    };
  },
  methods: {
    Download(item, index) {
      this.$store.commit("UPDATE_PrescriptionsList", item);
      console.log(index);
      this.$router.push({ name: "PrescriptionPDF" ,params:{ID:item.PatientReservationSessionID} });
    },
  },
  created() {
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    var Obj = {
      patientID: this.$store.state.AppActiveUser.Patient.ID,
    };
    this.$store
      .dispatch("patientList/SearchReservationSessionPrescriptions", Obj)
      .then((res) => {
        if (res.status == 200) {
          this.Model = res.data.Data;
                if(this.Model==null||this.Model.length==0){
             this.$vs.notify({
          title: this.$t("NoData"),
          text: this.$t("NoDataToshow"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });
        }
        }
      })
      .catch(() => {
        window.showError();
      });
  },
};
</script>
<style>
#RSPresciption .downloadCell {

  padding: 0px;
}
#RSPresciption table {
  border-collapse: collapse;
  border-radius: 36px !important;
  -moz-border-radius: 36px;
}

#RSPresciption td,
#RSPresciption th {
  border-left: solid #00000029 1px;
  border-top: solid #00000029 1px;
}

#RSPresciption th {
  border-top: none;
}

#RSPresciption th:first-child {
  border-top-left-radius: 36px;
  border-left: none;
  border-right: none;
}
#RSPresciption th:last-child {
  border-top-right-radius: 36px;
}
#RSPresciption td:first-child,
#RSPresciption th:first-child {
  border-left: none;
}
</style>
